import Keycloak from "keycloak-js";

const REALM_ROLES_MIN = [
  "fiscal",
  "fiscal-adjunto",
  "mesa-de-entrada",
  "asistente-fiscal",
  "ddea"
];

const REALM_ROLES = [
  "admin",
  "fiscal",
  "fiscal-adjunto",
  "mesa-de-entrada",
  "asistente-fiscal",
  "ddea",
  "external-institution"
];

const config = {
  "realm": "cej-realm",
  "auth-server-url": process.env.REACT_APP_AUTH_SERVER_URL,
  "resource": "cej-app-client",
  "public-client": true,
  "confidential-port": 0
}
console.log(process.env.REACT_APP_AUTH_SERVER_URL);

const keycloak = new Keycloak({
    url: process.env.REACT_APP_AUTH_SERVER_URL,
    realm: "cej-realm",
    clientId: "cej-app-client"
}, config);

export const checkRoles = (keycloak) => {
  const userRoles = keycloak?.tokenParsed?.realm_access?.roles;
  let role = undefined;
  if (!userRoles) return undefined;
  userRoles.map(userRole =>
    REALM_ROLES.map(realmRole => {
      if (userRole === realmRole) {
        role = userRole;
      }
    })
  )
  return role;
}

export const roles = (keycloak) => {
  if(!keycloak?.tokenParsed?.realm_access?.roles){ return undefined; }
  return keycloak?.tokenParsed?.realm_access?.roles.filter(role => REALM_ROLES.includes(role));
}

export const checkPermissions = (currentRoles, allowedRoles) => {
  let filtered = currentRoles.filter(role => allowedRoles.includes(role));
  return filtered.length > 0;
}

export default keycloak;
